.search-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
  font-family: Arial, sans-serif;
}

.search-input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 300px;
  margin-bottom: 10px;
}

.search-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
}

.search-button:hover {
  background-color: #45a049;
}

.search-results {
  margin-top: 20px;
  font-size: 20px;
}

.no-listings-message {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #333;
  border: 1px solid #ddd;
  margin: 20px;
  border-radius: 5px;
  flex-grow: 1;
}

/*.no-listings-message {*/
/*  flex-grow: 1; !* Allows the message to fill the container *!*/
/*  display: flex;*/
/*  justify-content: center; !* Center horizontally *!*/
/*  align-items: center; !* Center vertically *!*/
/*  text-align: center;*/
/*  font-size: 24px; !* Adjust font size as needed *!*/
/*}*/

.listings-container {
  height: 400px; /* Same as the map height */
  overflow-y: auto; /* Enables scrolling */
}
