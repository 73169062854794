.navbar {
    background-color: #333;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    position: relative; /* Set position to relative for absolute positioning of logout button */
}

.nav-logo {
    font-size: 1.5rem;
    position: absolute;
    left: 1rem; /* Position the logo to the left */
}

.nav-links {
    list-style: none;
    display: flex;
    justify-content: center; /* Center navbar links */
}

.nav-links li {
    margin: 0 1rem; /* Adjust margins as needed */
}

.nav-links a {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
}

.nav-links a:hover {
    color: #ddd;
}

.nav-logout {
    position: absolute;
    right: 1rem; /* Position logout button to the right */
    color: white;
    cursor: pointer; /* Optional: Change cursor on hover */
    transition: color 0.3s ease;
}

.nav-logout:hover {
    color: #ddd;
}
