.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Align items to the top */
  padding: 16px;
  gap: 32px; /* This adds space between the columns */
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content to the start */
}

/* Assign flex-basis or width to columns to control their size */
.column:first-child {
  flex: 3; /* First column takes 3 parts of the space */
}

.column:last-child {
  flex: 1; /* Second column takes 2 parts of the space */
}

/* You can also use width or flex-basis instead of flex if you want fixed sizes */

.column:last-child {
  width: 20%; /* Second column width */
}

.listing-card {
    padding: 24px;
    background: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-bottom: 16px; /* Add space below the card */
    border: none; /* Remove the border */
}

.listing-price {
    font-size: 28px; /* Larger font size for price */
    font-weight: 600; /* Increased weight */
    color: #E53935; /* A color that stands out */
    margin-bottom: 16px; /* More space below the price */
}

.listing-address {
    font-size: 18px; /* Slightly larger for importance */
    font-weight: 500; /* Medium weight */
    color: #333;
    margin-bottom: 8px;
}

.listing-location,
.listing-mls {
    font-size: 16px;
    color: #555; /* Slightly softer color */
    margin-bottom: 8px;
}

/* Optionally, you can add a hover effect to make it interactive */
.listing-card:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px); /* Slight lift */
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.salesperson-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  border: none; /* Removing the border */
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adding a shadow for depth */
  margin-bottom: 24px; /* Space below the card */
}

.salesperson-image {
  width: 100px; /* Increase size */
  height: 100px; /* Maintain aspect ratio */
  border-radius: 50%; /* Circular image */
    margin-bottom: 30px;
}

.salesperson-image img {
  width: 100px; /* Adjust to desired size */
  height: 100px; /* Adjust to desired size */
  border-radius: 50%; /* Makes the image circular */
  object-fit: cover; /* Ensures the image covers the space without distortion */
  margin-bottom: 30px; /* Increased space below the image */
  background-color: #f0f0f0; /* Placeholder color */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px; /* Placeholder text size */
  color: #666;
}

.salesperson-info h2 {
  margin: 0;
  font-size: 22px; /* Slightly larger font size */
  font-weight: 600; /* Semi-bold weight */
  color: #333; /* Darker color for better contrast */
  margin-bottom: 8px; /* Space below the name */
}

.salesperson-info p {
  font-size: 16px;
  color: #555; /* Softer color for text */
  margin-bottom: 12px; /* Consistent spacing */
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px; /* Space above the buttons */
}

.action-buttons button {
  background-color: #007BFF; /* Primary color */
  color: #fff;
  border: none;
  padding: 10px 20px; /* Larger padding for bigger buttons */
  margin: 8px;
  border-radius: 25px; /* More pronounced rounded corners */
  cursor: pointer;
  font-size: 16px; /* Text size */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for buttons */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.action-buttons button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.social-media button {
  background-color: #3b5998; /* Facebook blue */
  color: #fff;
  border: none;
  padding: 12px; /* Slightly larger padding for a touch-friendly button */
  border-radius: 50%; /* Circular button */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.social-media button:hover {
  background-color: #2a4373; /* Darker shade on hover */
}

/* Icons */
.phone-icon:before, .web-icon:before, .facebook-icon:before {
  display: inline-block;
  margin-right: 8px; /* Space between icon and text */
  /* Add content for icons using pseudo-elements if you're using font icons */
}

.brokerage-card {
  padding: 24px;
  border: none; /* Remove the border for a cleaner look */
  margin-top: 24px; /* More space from the preceding content */
    margin-bottom: 24px; /* Space below the card */
  background-color: #fff; /* Maintain a white background for cleanliness */
  border-radius: 8px; /* Rounded corners for modernity */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  transition: box-shadow 0.3s ease-in-out; /* Smooth transition for hover effect */
}

.brokerage-card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover for interaction */
}

.brokerage-card h2 {
  margin: 0;
  font-size: 22px; /* Larger size for more impact */
  font-weight: 600; /* Semi-bold for a sturdy appearance */
  color: #333; /* Dark grey for a professional tone */
  margin-bottom: 16px; /* More space below the heading for separation */
}

.brokerage-card p {
  font-size: 16px; /* Uniform font size for consistency */
  line-height: 1.6; /* Increased line height for better readability */
  color: #555; /* Softer text color for readability */
  margin-bottom: 12px; /* Consistent spacing between lines */
}

/* Icon adjustments for uniformity and alignment */
.phone-icon:before {
  /* Assuming you're using a pseudo-element for the icon */
  margin-right: 8px; /* Space between icon and text */
  vertical-align: middle; /* Aligns the icon with the text */
}

/* ... Other styles ... */

.listing-description {
    padding: 24px;
    margin-top: 24px; /* More space above the description for clear separation */
    border: 1px solid #e0e0e0; /* Softer border color */
    background-color: #fff;
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
}

.listing-description h2 {
    font-size: 24px; /* Slightly larger for prominence */
    font-weight: 600; /* Heavier weight for heading */
    margin-bottom: 12px; /* More space below the heading */
    color: #333; /* Darker color for heading */
}

.listing-description p {
    font-size: 16px;
    line-height: 1.6; /* Improved line height for readability */
    color: #555; /* Soften the text color */
    margin-bottom: 8px; /* Consistent spacing between paragraphs */
    text-align: justify; /* Justify text for a cleaner look */
}

/* Highlighting important information */
.listing-description p strong {
    color: #E53935; /* Use a standout color for important text */
    font-weight: 600; /* Bold for emphasis */
}

/* Hover effect for interaction */
.listing-description:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px); /* Slight lift */
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.property-summary {
    padding: 24px;
    margin-top: 24px; /* Consistent top margin with the description */
    border: 1px solid #e0e0e0; /* Softer border color */
    background-color: #fff;
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
}

.property-summary h2 {
    font-size: 24px; /* Slightly larger for prominence */
    font-weight: 600; /* Heavier weight for heading */
    margin-bottom: 16px; /* More space below the heading */
    color: #333; /* Darker color for heading */
}

.property-summary .summary-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Improved readability for the grid */
    column-gap: 24px; /* Increased space between columns */
    row-gap: 8px; /* Increased space between rows */
    padding-bottom: 16px; /* Padding at the bottom of the grid */
}

.property-summary p {
    font-size: 16px; /* Standard font size for consistency */
    line-height: 1.6; /* Improved line height for readability */
    color: #555; /* Soften the text color */
    margin-bottom: 0; /* Remove margin-bottom from paragraphs in grid */
}

/* Hover effect for interaction */
.property-summary:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px); /* Slight lift */
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}



.summary-details {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns for summary details */
  column-gap: 16px; /* Space between the columns */
  row-gap: 4px; /* Space between the rows */
}

/* ... existing styles ... */

.property-features {
    padding: 24px;
    margin-top: 24px; /* More space between sections */
    border: 1px solid #e0e0e0; /* Lighter border for a softer look */
    background-color: #fff;
    border-radius: 8px; /* Rounded corners for a modern feel */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
}

.property-features h2 {
    font-size: 24px; /* Larger font size for the main heading */
    font-weight: 600; /* Semi-bold for a more modern appearance */
    margin-bottom: 16px; /* Increased space below the heading */
    color: #333; /* Darker color for contrast */
}

.property-features .feature-category {
    margin-top: 24px; /* Increased space before each new category */
}

.property-features .feature-category h3 {
    font-size: 20px; /* Larger font size for subheadings */
    font-weight: 600; /* Semi-bold for distinction */
    margin-bottom: 12px; /* Increased space between heading and details */
    color: #333; /* Consistent color for headings */
}

.property-features p {
    font-size: 16px; /* Consistent font size */
    line-height: 1.6; /* Improved line height for readability */
    color: #555; /* Softer color for body text */
    margin-bottom: 8px; /* Consistent spacing between lines */
}

/* Hover effect for interactive feel */
.property-features:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px); /* Slight lift */
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

/* ... (existing styles) */

.sold-history {
  margin-top: 16px;
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 16px;
}

.sold-history h2 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 8px;
}

.sold-history table {
  width: 100%; /* Full width of the container */
  border-collapse: collapse; /* Collapse borders */
}

.sold-history th,
.sold-history td {
  border: 1px solid #ddd; /* Light grey border */
  padding: 8px;
  text-align: left; /* Align text to the left */
}

.sold-history th {
  background-color: #f2f2f2; /* Light grey background */
  font-weight: bold;
}

/* Style for alternating row colors */
.sold-history tr:nth-child(even) {
  background-color: #f9f9f9;
}

.sold-history tr:hover {
  background-color: #eaeaea; /* Light hover effect */
}

.blur {
  filter: blur(8px);
  position: relative;
}


.sold-history {
  position: relative;
}

.sold-history-content {
  filter: blur(8px);
}

.login-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: none; /* Ensure the overlay is not blurred */
}

.login-button {
  position: absolute;
  top: 50%;
  left: 50%;
    width: 80%;
  transform: translate(-50%, -50%);
}





