@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Roboto:wght@300;400;700&display=swap');

.welcome-container {
    font-family: 'Open Sans', sans-serif;
    color: #333;
    line-height: 1.6;
}

.welcome-header {
    text-align: center;
    height: 100vh; /* Set height to full viewport height */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    background-color: #f8f9fa;
}

.welcome-header h1 {
    font-size: 3.5em;
    margin-bottom: 20px;
    font-weight: 700;
    color: #007bff;
}

.welcome-header p {
    font-size: 1.6em;
    color: #555;
    font-weight: 400;
}

.explore-neighborhoods, .about-us {
    padding: 20px;
    margin-bottom: 30px;
}

.neighborhood-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.neighborhood-card {
    width: 250px;
    border: 1px solid #ddd;
    padding: 20px;
    margin: 10px;
    text-align: center;
}

.neighborhood-card h3 {
    margin-bottom: 5px;
    color: #007bff;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
}

.neighborhood-card p {
    font-size: 0.9em;
    color: #666;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}

.about-us {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}
