.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f7f6; /* Adjust the background color as needed */
}

.signup-form {
  width: 300px;
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.signup-form h2 {
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.input-group {
  margin-bottom: 20px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box; /* Include padding in the input width */
}

button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #009688;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #00796b;
}

.login-text {
  margin-top: 20px;
  text-align: center;
  color: #333;
}

.login-text a {
  color: #009688;
  text-decoration: none;
}

.login-text a:hover {
  text-decoration: underline;
}

.error-banner {
  background-color: #f8d7da;
  color: #721c24;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.8em;
  text-align: center;
}

.modal-overlay {
  position: fixed; /* Fixed position to stay in place */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* High z-index to be on top of other content */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px; /* Adjust as needed */
  max-height: 80%; /* Adjust as needed */
  overflow-y: auto; /* Allows scrolling if the content is too long */
}

.input-group.terms {
    display: flex; /* Enables Flexbox */
    align-items: center; /* Vertically centers the items in the flex container */
}

.input-group.terms input[type="checkbox"] {
    flex: 0 0 15%; /* Does not grow, does not shrink, and takes up 15% of the flex container */
}

.terms-link {
  color: #009688;
  cursor: pointer;
}
