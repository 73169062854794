.footer {
    color:black;
    font-family: Arial, sans-serif; /* Change to the font used in the card */
    font-family: "Roboto Condensed", sans-serif;
    background-color: white;

}

.card-content {
    padding: 20px;
    margin-left: 50px;
    margin-right: 50px;
}

.card-header h1 {
    font-size: 24px; /* Adjust font size as needed */
    margin: 0;
}

.card-header h2 {
    font-size: 18px; /* Adjust font size as needed */
    color: grey; /* Adjust color as needed */
    margin: 0;
}

.card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px; /* Adjust as needed */
}

.contact-info p {
    margin: 0;
    font-size: 18px; /* Adjust font size as needed */
}

.company-logo img {
    width: 300px; /* Adjust based on actual logo size */
    height: auto;
}