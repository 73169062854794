.email-verification-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f7f6; /* Adjust the background color as needed */
}

.email-verification-form {
  width: 300px;
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
}

.email-verification-form h2 {
  color: #333;
  margin-bottom: 20px;
}

.email-verification-form p {
  margin-bottom: 20px;
  color: #666;
}

.input-group {
  margin-bottom: 20px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box; /* Include padding in the input width */
}

button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #009688;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #00796b;
}

/* Add to EmailVerification.css */

.resend-code {
  margin-top: 10px;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #00695c;
  color: white;
  cursor: pointer;
  font-size: 14px;
}

.resend-code:hover {
  background-color: #004d40;
}

.error-banner {
  background-color: #f8d7da;
  color: #721c24;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.8em;
  text-align: center;
}

